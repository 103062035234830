import { smdRequest } from './smdRequest';

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
    isBrowser() && window.localStorage.getItem("gatsbyUser")
        ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
        : {user: {email: ''}, }

const setUser = user =>
    isBrowser() ? window.localStorage.setItem("gatsbyUser", JSON.stringify(user)) : null;

export const handleLogin = async ({ username, password }) => {
    let response = await smdRequest('/auth/local', {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify({
            identifier: username,
            password: password,
        })
    })

    let data = await response.json();

    if ('error' in data) {
        throw Error(data.message[0].messages[0].message);
    }

    return setUser(data);
}

export const isLoggedIn = () => {
    const user = getUser()

    return !!user.jwt
}

export const logout = callback => {
    setUser({})
    callback()
}