export const smdRequest = (endpoint, options={}) => {
    //let fullEndpoint = 'https://admin.smd.wtf' + endpoint;
    //let fullEndpoint = 'http://localhost:1337' + endpoint;
    let fullEndpoint = process.env.API_URL + endpoint;

    return fetch(fullEndpoint, options);
}

export const smdApiRequest = (endpoint, options={}) => {
    let fullEndpoint = 'https://smd.wtf' + endpoint;

    return fetch(fullEndpoint, options);
}